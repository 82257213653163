<template>
  <div class="AddNewSupplierTwo" v-if="show">
    <div class="discount-preset">
      <NeoTitle :text="$t('customerConfig.discount_preset')" class="_mb-21" />
      <DiscountPresetTable :edit="true" :datap="info.discountList" :info="info" />
    </div>

    <PriceListMod :info="info" />
    <footer class="_mt-35">
      <a-button class="_mr-10" @click="prev">{{ $t('prev') }}</a-button>
      <a-button v-if="!info.status" @click="sendEmail" :loading="loading" class="_w-120" type="primary">{{
        $t('customerConfig.send_ok_email')
      }}</a-button>
    </footer>
  </div>
</template>

<script>
import DiscountPresetTable from '@/components/DiscountPresetTable'
import PriceListMod from '@/components/PriceListMod'

export default {
  components: {
    DiscountPresetTable,
    PriceListMod,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataSourceOne: [],
      discountList: [],
      addPriceVisible: false,
      agencyInfo: {
        id: null,
        currency: null,
      },
      priceFileList: [],
      edit: false,
      selectedPrice: '',
      loading: false,
    }
  },
  mounted() {},
  methods: {
    addPrice() {
      this.addPriceVisible = true
    },
    // 价格子组件触发的父组件的
    onAddPrice() {
      this.$refs.PriceListTable.getPriceList()
      this.addPriceVisible = false
    },
    prev() {
      console.log('上一步')
      this.$emit('prev')
    },
    async sendEmail() {
      try {
        this.loading = true
        const { id, supTenantCode } = this.info
        const params = { id, supTenantCode }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('sendConfirmEmail', params)
        console.log(`data`, data)
        this.loading = false
        if (data) {
          this.$message.success(this.$t('customerConfig.send_email_succ'))
          setTimeout(() => {
            this.$router.push({ path: `${this.$g.route.coConfig_home}?tab=2` })
          }, 200)
        } else {
          this.$message.error(this.$t('customerConfig.send_email_fail'))
        }
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.AddNewSupplierTwo {
  ::v-deep {
    .ant-descriptions-bordered .ant-descriptions-item-label {
      background-color: @tbl-header-bgc;
    }
  }
}
</style>
