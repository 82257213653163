<template>
  <a-upload
    class="BatchImportPriceBtn"
    accept=".xlsx"
    :beforeUpload="handleBeforeUpload"
    :action="uploadAction"
    :showUploadList="false"
    :multiple="false"
    :disabled="uploading"
    :withCredentials="true"
    :headers="uploadHeaders"
    :data="uploadParam"
    @change="handleUploadChange"
  >
    <a-button :loading="uploading" class="_h-24">
      <a-icon type="upload" v-if="!uploading" />{{ $t('companyInfo.btn_bulk_import') }}
    </a-button>
  </a-upload>
</template>

<script>
import Cookies from 'js-cookie'

// 批量导入供应商价格
export default {
  name: 'BatchImportPriceBtn',
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      uploading: false,
      uploadHeaders: {
        token: Cookies.get(window.$g.storageKey.TOKEN) || '',
      },
    }
  },
  computed: {
    uploadAction() {
      return window.SITE_CONFIG['apiURL'] + '/customer/supPrice/batchImport'
    },
    uploadParam() {
      return {
        cusEntityId: this.agencyId,
        entityId: this.info.id,
        supTenantCode: this.info.supTenantCode,
      }
    },
    agencyId() {
      return this.$store.state.app.userInfo.agencyId
    },
  },
  methods: {
    handleBeforeUpload(file) {
      const { name } = file
      const suffix = name.slice(name.lastIndexOf('.'))
      if (suffix !== '.xlsx') {
        this.$message.error(this.$t('prompt.upload_illegal_format', { name }))
        return false
      }
      // 文件大小超过5M
      if (file.size / 1024 / 1024 > 5) {
        this.$message.error(this.$t('validate.upload_max_size', { filename: name, size: 5 }))
        return false
      }
      this.uploading = true
    },
    // 模板上传
    handleUploadChange({ file }) {
      if (file.status === 'done') {
        this.uploading = false
        if (file.response.code !== 0) {
          return this.$message.error(file.response.msg)
        } else {
          const { efficientCount, invalidCount } = file.response.data
          const msg = this.$t('customerConfig.importSuccess', { efficientCount, invalidCount })
          this.$emit('reload')
          if (!efficientCount) {
            return this.$message.warning(msg)
          } else {
            return this.$message.success(msg)
          }
        }
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
