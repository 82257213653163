<template>
  <div class="PriceListMod">
    <div class="price-list _mt-30">
      <div class="_mb-15 _flex-just-sb">
        <NeoTitle :text="$t('companyInfo.price_list')" />
        <section class="price-list-action _flex _ali-cen">
          <a-button @click="addPriceVisible = true" class="_h-24 _mr-10" type="primary">{{
            $t('companyInfo.title_new_price')
          }}</a-button>
          <BatchImportPriceBtn :info="info" @reload="handleReload" />
          <a-button type="link" :href="$g.price_list_info_template">
            {{ $t('orderInfo.download_template') }} <a-icon type="download" />
          </a-button>
        </section>
      </div>
      <PriceListTable ref="PriceListTable" :showAction="true" :info="info" />
    </div>
    <!-- 新增价格面板 -->
    <a-modal
      :title="$t('companyInfo.title_new_price')"
      :visible="addPriceVisible"
      :footer="null"
      @cancel="addPriceVisible = false"
      :width="684"
      :maskClosable="false"
    >
      <AddPrice :info="info" @addPrice="onAddPrice" v-if="addPriceVisible" />
    </a-modal>
  </div>
</template>

<script>
import BatchImportPriceBtn from './BatchImportPriceBtn'
import PriceListTable from '@/components/PriceListTable'
import AddPrice from '@/components/AddPrice'
export default {
  name: 'PriceListMod',
  components: {
    BatchImportPriceBtn,
    PriceListTable,
    AddPrice,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    info(info) {
      console.error(`info`, info)
      // this.reloadList()
    },
  },
  data() {
    return {
      addPriceVisible: false,
      priceFileList: [],
      isReload: true,
    }
  },
  methods: {
    handleReload() {
      this.reloadList()
    },
    // 价格子组件触发的父组件的
    onAddPrice() {
      this.reloadList()
      this.addPriceVisible = false
    },
    reloadList() {
      this.$refs.PriceListTable.fetchAll()
    },
  },
}
</script>

<style lang="less" scoped></style>
