<template>
  <!-- /coConfig/supplierDetail里新增价格的弹窗 -->
  <div class="AddPrice">
    <a-form-model ref="priceForm" v-bind="form" :colon="false" :rules="rules" :model="formdata">
      <!-- 服务类型 -->
      <a-form-model-item prop="serviceType" :label="$t('priceList.service_type')">
        <a-select
          style="width: 372px !important"
          v-model="formdata.serviceType"
          :placeholder="$t('priceList.service_type_holder')"
        >
          <a-select-option v-for="(item, index) in serviceType" :key="index" :value="item.id">{{
            item.label
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 文档类型 -->
      <a-form-model-item prop="documentType" :label="$t('priceList.doc_type')">
        <a-select
          style="width: 372px !important"
          v-model="formdata.documentType"
          :placeholder="$t('priceList.doc_type_holder')"
        >
          <a-select-option v-for="(item, index) in docType" :key="index" :value="item.id">{{
            item.label
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 源语言 -->
      <a-form-model-item
        :key="selected"
        :label="$t('priceList.source_language')"
        :validate-status="getValidateStatus(formdata.sourceCode, formdata.targetCode, formdata.sourceCode)"
        :help="getValidateTip(formdata.sourceCode, formdata.targetCode, formdata.sourceCode)"
      >
        <!-- :rules="selected ? undefined : [validate.required]" -->
        <a-input default-value="---" v-if="selected" :disabled="selected"></a-input>
        <a-select
          v-else
          show-search
          allowClear
          v-model="formdata.sourceCode"
          :disabled="selected"
          :placeholder="$t('priceList.source_language_holder')"
          :filter-option="filterOption"
          style="width: 372px !important"
        >
          <a-select-option
            v-for="(item, index) in langList"
            :disabled="$g.noEmpty(formdata.targetCode) && item.code === formdata.targetCode"
            :key="index"
            :value="item.code"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <!-- 目标语言 -->
      <a-form-model-item
        :key="~~selected"
        :label="$t('priceList.target_language')"
        :validate-status="getValidateStatus(formdata.sourceCode, formdata.targetCode, formdata.targetCode)"
        :help="getValidateTip(formdata.sourceCode, formdata.targetCode, formdata.targetCode)"
      >
        <!-- :rules="selected ? undefined : [validate.required]" -->
        <a-input default-value="---" v-if="selected" :disabled="selected"></a-input>
        <a-select
          v-else
          style="width: 372px !important"
          show-search
          allowClear
          v-model="formdata.targetCode"
          :disabled="selected"
          :placeholder="$t('priceList.target_language_holder')"
          :filter-option="filterOption"
        >
          <a-select-option
            v-for="(item, index) in langList"
            :disabled="$g.noEmpty(formdata.sourceCode) && item.code === formdata.sourceCode"
            :key="index"
            :value="item.code"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <!-- 价格 -->
      <a-form-model-item prop="price" :label="$t('priceList.price')">
        <a-input v-model="formdata.price" :placeholder="$t('priceList.price_holder')"></a-input>
      </a-form-model-item>
      <!-- 单位 -->
      <a-form-model-item prop="unit" :label="$t('priceList.unit')">
        <a-select style="width: 372px !important" v-model="formdata.unit" :placeholder="$t('priceList.unit_holder')">
          <a-select-option v-for="(item, index) in unit" :key="index" :value="item.dictValue">{{
            item.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" class="submit-btn" @click="onSubmit" :loading="subLoading">{{
          $t('submit')
        }}</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import numeral from 'numeral'
import { mapState } from 'vuex'
const { validate } = window.$g

export default {
  name: 'AddPrice',
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      validate,
      form: {
        labelCol: { flex: '80px' },
        wrapperCol: { flex: 'auto' },
      },
      // options
      fielName: {
        value: 'dictValue',
        label: 'dictLabel',
        children: 'children',
      },
      // 新增价格表单
      formdata: {
        serviceType: undefined,
        documentType: undefined,
        sourceCode: undefined,
        targetCode: undefined,
        price: undefined,
        unit: undefined,
      },
      // 表单验证
      rules: {
        serviceType: [validate.required],
        // field: [validate.required],
        documentType: [validate.required],
        // sourceCode: [validate.required],
        // targetCode: [validate.required],
        price: [validate.float_3, validate.required],
        unit: [validate.required],
      },
      selected: false,
      subLoading: false,
      editLoading: false,
      numeral,
      serviceType: [],
      docType: [],
      baseInfo: [],
    }
  },
  computed: {
    ...mapState('app', {
      langList: (state) => state.langList,
      industryList: (state) => state.industryList,
    }),
    unit() {
      return this.$store.getters['app/getDictByType']('SALE_UNIT')
    },
  },
  mounted() {
    this.getBaseInfo()
    this.getCusServiceTypeList()
    this.getCusDocumentTypeList()
  },
  methods: {
    getValidateStatus(sourceCode, targetCode, code) {
      const error = !!sourceCode !== !!targetCode && !code
      if (error) {
        return 'error'
      } else {
        return ''
      }
    },
    getValidateTip(sourceCode, targetCode, code) {
      const error = !!sourceCode !== !!targetCode && !code
      if (error) {
        return this.$t('validate.required')
      } else {
        return ''
      }
    },
    langPairNoSingle() {
      return !!this.formdata.sourceCode === !!this.formdata.targetCode
    },
    async getBaseInfo() {
      try {
        this.loading = true
        const data = await this.$http({ key: 'supEntityInfo', params: { id: this.info.id } })
        this.baseInfo = data
        console.log(`this.baseInfo`, this.baseInfo)
      } catch (error) {
        this.$httpNotify(error)
      }
    },
    // 客户服务类型表-列表
    async getCusServiceTypeList() {
      const agencyId = this.$store.state.app.userInfo.agencyId
      try {
        const data = await this.$http('cusMaterialList', { entityId: agencyId, type: 1 })
        console.log(`serviceType`, data)
        this.serviceType = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 客户文档类型表-列表
    async getCusDocumentTypeList() {
      const agencyId = this.$store.state.app.userInfo.agencyId
      try {
        const data = await this.$http('cusMaterialList', { entityId: agencyId, type: 2 })
        console.log(`docType`, data)
        this.docType = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    onSubmit() {
      this.$refs.priceForm.validate(async (valid) => {
        console.log(`this.langPairNoSingle()`, this.langPairNoSingle())
        if (valid && this.langPairNoSingle()) {
          this.subLoading = true
          try {
            const params = {
              entityId: this.info.id,
              price: this.formdata.price,
              unit: this.formdata.unit,
              serviceId: this.formdata.serviceType,
              documentId: this.formdata.documentType,
              targetCode: this.formdata.targetCode,
              sourceCode: this.formdata.sourceCode,
              supTenantCode: this.baseInfo.supTenantCode,
            }
            console.log(`params`, params)
            // return
            // eslint-disable-next-line no-unused-vars
            // eslint-disable-next-line no-unreachable
            await this.$http('supPriceSave', params)
            this.subLoading = false
            // TODO: 触发父组件，更新数据
            this.$message.success(this.$t('prompt.create_success'))
            this.$emit('addPrice')
          } catch (err) {
            this.subLoading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    getDictTree(val) {
      let result = ''
      if (val === null) {
        result = ''
      } else {
        const str = val?.split('-')[0] + '-' + val?.split('-')[1]
        result = [str, val]
      }
      return result
    },
    // async getPrice() {
    //   try {
    //     const data = await this.$http({ key: 'getPriceById', params: { id: this.priceInfo.id } })
    //     this.formdata = data
    //     this.formdata.field = this.getDictTree(data.field)
    //     // 判断是否可选
    //     // if (this.formdata.serviceType !== '1-1' && this.formdata.serviceType !== '1-2') {
    //     //   this.selected = true
    //     //   this.formdata.sourceCode = undefined
    //     //   this.formdata.targetCode = undefined
    //     //   this.formdata.field = undefined
    //     // }
    //   } catch (err) {
    //     this.$httpNotify(err)
    //   }
    // },
    // onSelectType(val) {
    //   if (val !== '1-1' && val !== '1-2') {
    //     this.selected = true
    //   } else {
    //     this.selected = false
    //   }
    //   this.formdata.field = undefined
    //   this.formdata.sourceCode = undefined
    //   this.formdata.targetCode = undefined
    // },
    // 筛选过滤
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>

<style lang="less" scoped>
.AddPrice {
  padding: 5px 96px;
  .submit-btn {
    min-width: 100px;
    margin-left: 74px;
  }
  /deep/.ant-form-item {
    margin-bottom: 15px;
    display: flex;
  }
}
</style>
